import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfoDialogComponent } from '@/_components/info-dialog/info-dialog.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  public items: MenuItem[] = [];
  constructor(private router: Router, private dialog: MatDialog) {
    this.items = [
      // {
      //   routerLink: "/dashboard",
      //   icon: "home",
      //   name: "Dashboard",
      //   fake: true
      // },
      //{
      //  routerLink: "/events",
      //  icon: "alert-triangle",
      //  name: "Events"
      //},
      {
        routerLink: "/assets",
        icon: "folder",
        name: "Assets"
      },
      {
        routerLink: "/data-sources",
        icon: "image",
        name: "Data Sources"
      },
      {
        routerLink: "/data-layers",
        icon: "file",
        name: "Data Layers"
      },
      //{
      //  routerLink: "/map",
      //  icon: "globe",
      //  name: "Map"
      //},
      {
        routerLink: "/variables",
        icon: "sliders",
        name: "Variables"
      },
      {
        routerLink: "/reports",
        icon: "file-text",
        name: "Reports"
      },
      {
        routerLink: "/report/create",
        icon: "file-text",
        name: "Create"
      },

      //{
      //  routerLink: "/predictive-model",
      //  icon: "eye",
      //  name: "Predictive model",
      //  disabled: true
      //}
    ];
  }

  ngOnInit(): void {
  }

  public onMenuClick(item: MenuItem): void {
    if (item.fake) {
      var messages: string[] = ["Thank you for using Spottitt MF. This feature is not available yet."];

      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: 'error',
          messages: messages
        }
      });
    } else {
      this.router.navigate([item.routerLink]);
    }
  }
}

interface MenuItem {
  routerLink: string,
  icon: string,
  name: string,
  fake?: boolean,
  disabled?: boolean
}
