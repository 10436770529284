import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { Home, File, User, LogOut, LogIn, Eye, EyeOff, ZoomIn, AlertTriangle, Folder, Globe, Key, Filter, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Info, Trash2, Trash, Map, Image, Table, FileText, Sliders, Grid, Download, X, MoreVertical, Columns } from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = { Home, File, User, LogOut, LogIn, Eye, EyeOff, ZoomIn, AlertTriangle, Folder, Globe, Key, Filter, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Info, Trash, Trash2, Map, Image, Table, FileText, Sliders, Grid, Download, X, MoreVertical, Columns };

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
