<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Report</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Report Name</mat-label>
      <input matInput [(ngModel)]="reportName" required>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <div class="my-1">
      <label>Choose dimension</label>
      <ng-select [items]="dimensions"
                 bindLabel="label"
                 placeholder="Choose dimension"
                 (clear)="onDimensionClear($event)"
                 (change)="onDimensionChange($event)"
                 [(ngModel)]="selectedDimension"
                 [searchFn]="customSearchFn">
        <ng-template ng-option-tmp let-item="item">
          {{ item.label }}
        </ng-template>
      </ng-select>
    </div>
    <div class="my-1">
      <form [formGroup]="reportForm" novalidate>
        <ng-select (search)="onColumnSearch($event)"
                   formControlName="columns"
                   class="columnsSelect"
                   placeholder="Choose columns"
                   [searchFn]="customColumnsSearchFn"
                   (close)="onColumnSelectClose()">
          <ng-template ng-header-tmp>
            <div class="customBoxWrapper">
              <ul class="categoriesList">
                @for (dataLayer of dataLayers; track dataLayer.name) {
                <li *ngIf="dataLayer.visible">
                  <span (mouseenter)="onMouseEnterColumnGroup(dataLayer)" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + dataLayer.id">
                    {{dataLayer.name}}
                    <i-feather name="chevron-down" class="xsm"></i-feather>
                  </span>
                  <ul *ngIf="dataLayer.colDefs.length > 0" [id]="dataLayer.id" class="columnsList show">
                    @for (column of dataLayer.colDefs; track column.title) {
                    <li *ngIf="!column.selected && column.visible"><span [ngClass]="{active: column.active}" (click)="selectColumn(column, dataLayer.id, dataLayer.name)" (mouseenter)="onMouseEnterColumnOption(column)">{{column.title}}</span></li>
                    }
                  </ul>
                </li>
                }
              </ul>

              <!--<div role="listbox" class="ng-dropdown-panel-items scroll-host">
                <ng-container *ngFor="let dataLayer of dataLayers; let i=index">
                  <div class="ng-option">
                    {{dataLayer.name}}
                  </div>
                  @for (column of dataLayer.columns; track column.name) {
                    <div *ngIf="!column.selected" class="ng-option"><span (click)="selectColumn(column, dataLayer.id)" (mouseenter)="onMouseEnterColumnOption(column)">{{column.name}}</span></div>
                  }
                </ng-container>
              </div>-->

              <div class="hintBox" [ngClass]="{'show': showHint}">
                {{this.hintText}}
              </div>
            </div>
          </ng-template>
          <ng-template ng-typetosearch-tmp></ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm"></ng-template>
          <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm"></ng-template>
        </ng-select>
      </form>
    </div>
    <div class="my-1">
      <button (click)="create()" class="btn btn-primary" [disabled]="createLoader">
        <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
        Create
      </button>
    </div>
  </div>
  <div class="col-9">
    <div *ngIf="selectedDimension">
      <h4>Dimension</h4>
      <ul class="selected-list">
        <li class="selected-item"><span class="label">{{selectedDimension.label}}</span></li>
      </ul>
    </div>
    <div *ngIf="selectedColumns.length > 0">
      <h4>Columns</h4>
      <ul cdkDropList (cdkDropListDropped)="dropColumn($event)" class="selected-list">
        <li *ngFor="let column of selectedColumns" cdkDrag class="selected-item">
          <span class="drag-handle" cdkDragHandle>
            <i-feather name="more-vertical" class="xsm"></i-feather>
            <i-feather name="more-vertical" class="xsm"></i-feather>
          </span>
          <span class="label">{{column.title}} - {{column.parentName}}</span>
          <button class="remove" (click)="removeColumn(column)"><i-feather name="x" class="xsm"></i-feather></button>
        </li>
      </ul>
    </div>
    <hr />
    <h5>Preview</h5>
    <h4>{{reportName}}</h4>
    <app-grid class="w-100" [data]="fakeData" [config]="previewGridConfig"></app-grid>
  </div>
</div>
