import { GridComponent } from "@/_components/grid";
import { ResourcesService } from "@/_services/resources.service";
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapLayer } from "@/map/models";
import { MapLayerService } from "@/_services/map-layer.service";
import {ActivatedRoute, Router} from "@angular/router";
import { SatelliteImagesService } from "@/_services/satellite-images.service";
import WKT from "ol/format/WKT";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { SateliteImportComponent } from "@/_components/satelite-import";
import { createEmpty, extend } from "ol/extent";
import { JsonResponse } from "@/_models/response";
import { environment } from "@envs/environment";
import { ConfirmDialogComponent } from "@/_components/confirm-dialog";

@Component({
  selector: 'app-satellite-images',
  providers: [
    {
      provide: ResourcesService,
      useClass: SatelliteImagesService
    }
  ],
  templateUrl: './satellite-images.component.html',
  styleUrls: ['./satellite-images.component.scss']
})

export class SatelliteImagesComponent {
  public items: Resource[] = [];
  http: HttpClient;
  public gridConfig: any;
  public filter: any;
  public showDelete: boolean = false;
  @ViewChild('dataGrid') dataGrid!: GridComponent;
  constructor(
    private _http: HttpClient,
    private dialog: MatDialog,
    public _resourceService: ResourcesService,
    private _mapLayerService: MapLayerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.showDelete = (this.route.snapshot.queryParamMap.get('delete')) == "1";
    this.http = _http;
    this.refreshGrid();
    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: "Name",
          field: "name",
          sortColumn: "name",
        },
        {
          title: "Date",
          field: "satelliteImageDate",
          sortColumn: "date",
        },
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'map',
          btnTooltip: 'Show on map',
          action: this.showOnMap,
          paramFields: ['id']
        },
        {
          title: '',
          type: 'actionpossible',
          check: 'downloadable',
          btnText: '',
          btnIconName: 'download',
          btnTooltip: 'Download',
          action: this.download,
          paramFields: ['id']
        }
      ],
      filters: [
        {
          field: "name",
          title: "Name",
          type: "string"
        },
        {
          field: "date",
          title: "Date",
          type: "date"
        }
      ],
      selectable: true
    }

    if(this.showDelete) {
      this.gridConfig.colDefs.push({
        title: '',
        type: 'action',
        btnText: '',
        btnIconName: 'trash',
        btnTooltip: 'Delete',
        action: this.remove,
        paramFields: ['id']
      })
    }
  }
  refreshGrid() {
    if (this.dataGrid != undefined) {
      this.dataGrid.refreshGrid();
    }
  }
  showOnMap(item: any, goToMap: boolean = true, addAtTheEnd: boolean = true) {
    let imageLayer = new MapLayer();
    imageLayer.name = item.name;
    imageLayer.id = "image" + item.id;
    let extent = undefined;
    if (item.wkt != null) {
      let feature = new WKT().readFeature(item.wkt, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
      extent = feature.getGeometry()?.getExtent();
    }
    imageLayer.olLayer = new TileLayer({
      extent: extent,
      preload: Infinity,
      source: new TileWMS({
        url: item.wmsUrl,
        params: { 'LAYERS': item.layers, 'TILED': true },
        transition: 0
      })
    });
    if (goToMap) {
      this._mapLayerService.addLayer(imageLayer, false, addAtTheEnd);
      this._mapLayerService.addEvent('', 'zoomToExtent', { extent: extent });
      this.router.navigate(['/map']);
    }
    else {
      this._mapLayerService.addLayer(imageLayer, false, addAtTheEnd);
    }
    return extent
  }
  showOnMapMulti() {
    let extent = createEmpty();
    this.dataGrid.selectedItemsList.forEach((item: any, index) => {
      let layerExtent = this.showOnMap(item, false);
      if (layerExtent != undefined) {
        extend(extent, layerExtent);
      }
      this._mapLayerService.addEvent('', 'zoomToExtent', { extent: extent });
    });

    this.router.navigate(['/map']);
  }

  remove(item?: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        icon: "alert-triangle",
        message: "Are you sure to delete \"" + item.name + "\" data source?",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.delete<JsonResponse>(`${environment.apiUrl}/api/SatelliteImage/Delete?id=${item.id}`).subscribe(result => {
          if (result) {
            if (this.dataGrid != undefined) {
              this.dataGrid.refreshGrid();
            }
          }
        });
      }
    });
  }

  openUploadSatImage() {
    const dialogRef = this.dialog.open(SateliteImportComponent, {
      data: {},
      width: '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshGrid();
    });
  }

  download(item?: any) {
    this.http.get<any>(`${environment.apiUrl}/api/SatelliteImage/GetFileLink?id=` + item.id).subscribe({
      next: (result: any) => {
        const url = result.item;
        const link = document.createElement('a');
        link.href = url;
        //link.setAttribute('download', result.item.fileName);
        document.body.appendChild(link);
        link.click();
      },
      error: (error: Error) => console.error(error)
    });
  }
}

interface Resource {
  id: number
  name: string
  location: string
  share: string
  type: string
  size: number
  status: number
}
