import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { AssetsComponent } from "./assets";
import { AnalysisComponent } from "./analysis";
import { MapComponent } from "./map";
import { ForgotPasswordComponent } from "./forgot-password";
import { ResetPasswordComponent } from "./reset-password";
import { UsersComponent } from './users';
import { ReportsComponent } from './reports';
import { EventsComponent } from './events';
import { SatelliteImagesComponent } from "@/satellite-images";
import { ReportDetailsComponent } from './report-details';
import { VariablesComponent } from '@/variables';
import { CreateReportComponent } from './create-report';
import {ReportCreateComponent} from "@/report/create";

const routes: Routes = [
  { path: 'data-layers', component: AnalysisComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'assets', component: AssetsComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'data-sources', component: SatelliteImagesComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'map/:itemId', component: MapComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:userid/:token', component: ResetPasswordComponent },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },
  { path: 'report-details/:itemId', component: ReportDetailsComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },
  { path: 'report-create', component: CreateReportComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },
  { path: 'events', component: EventsComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },
  { path: 'variables', component: VariablesComponent, canActivate: [AuthGuard], data: { reuseRoute: true } },

  { path: 'report/create', component: ReportCreateComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },
  { path: 'report/list', component: ReportsComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },
  { path: 'report/details/:itemId', component: ReportDetailsComponent, canActivate: [AuthGuard], data: { reuseRoute: false } },

  // otherwise redirect to home
  { path: '**', redirectTo: 'assets' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
